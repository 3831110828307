<script>
import Vue from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCheckout } from '/~/composables/checkout'

export default {
  name: 'payment-checkout-points',
  components: {
    BaseIcon,
  },
  setup() {
    const { payment, checkoutByAccount } = useCheckout()

    async function onClick() {
      if (payment.submitting) {
        return
      }

      try {
        await checkoutByAccount()
      } catch (error) {
        Vue.notify({
          text: error.message,
          type: 'error',
          duration: 5000,
        })
      }
    }

    return {
      payment,
      onClick,
    }
  },
}
</script>

<template>
  <div
    class="relative flex items-center rounded-lg border bg-white p-[15px]"
    :class="{
      'cursor-pointer': !payment.submitting,
    }"
    @click="onClick"
  >
    <div
      class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
    >
      <base-icon svg="pba" :size="200" />
    </div>
    <div class="ml-[15px] font-bold">
      <span class="">Pay by Account</span>
    </div>
  </div>
</template>
