<script>
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCheckout } from '/~/composables/checkout'
import { useCms } from '/~/composables/cms/use-cms'
import { useEwallet } from '/~/composables/ewallet'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-ewallet-item',
  components: {
    BaseIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    splitEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      selectedEwallet,
      selectedPoints,
      selectedCard,
      selectedBankAccount,
      payWithEwallet,
      payWithCoupons,
      subTotalNoCoupons,
      selectedAmount,
      getTransactionFees,
      payment,
      isEwalletDisabled,
    } = useCheckout()
    const { isPurchasesFeesEnabled } = useProvider()
    const { burnPointsRateOrder } = usePoints()
    const { ewalletBalance } = useEwallet()
    const { ewalletLabels } = useCms()

    return {
      bottomSheet,
      selectedEwallet,
      selectedPoints,
      selectedCard,
      selectedBankAccount,
      payWithEwallet,
      payWithCoupons,
      subTotalNoCoupons,
      isPurchasesFeesEnabled,
      formatDollar,
      selectedAmount,
      getTransactionFees,
      burnPointsRateOrder,
      payment,
      isEwalletDisabled,
      modal,
      ui,
      ewalletBalance,
      ewalletLabels,
    }
  },
  computed: {
    isSelected() {
      return Boolean(this.selectedEwallet)
    },
    isUnselectable() {
      if (this.payment.submitting) {
        return true
      }

      if (this.isEwalletDisabled) {
        return true
      }

      return (
        this.ewalletBalance === 0 ||
        (!this.splitEnabled && this.ewalletBalance < this.remainingTotal) ||
        this.payWithCoupons >= this.remainingTotal
      )
    },
    details() {
      if (this.isSelected) {
        return `Using ${formatDollar(this.payWithEwallet)}`
      }

      return `${this.ewalletLabels.ewalletCashBalance} ${formatDollar(
        this.ewalletBalance
      )}`
    },
    remainingTotal() {
      return Math.max(this.subTotalNoCoupons, 0)
    },
    feesLabel() {
      return this.isPurchasesFeesEnabled ? 'Includes transaction fee' : null
    },
  },
  methods: {
    async onClick() {
      if (this.isUnselectable) return

      if (!this.splitEnabled) {
        this.selectedEwallet = this.isSelected
          ? null
          : {
              ...this.item,
              calculatedAmount: this.remainingTotal,
            }

        this.getTransactionFees()
      } else {
        const splitModalProps = {
          currentItem: this.item,
          selectedPoints: this.selectedPoints,
          burnPointsRate: this.burnPointsRateOrder,
          selectedEwallet: this.selectedEwallet,
          selectedCard: this.selectedCard,
          selectedBankAccount: this.selectedBankAccount,
          selectedAmount: this.selectedAmount,
          total: this.remainingTotal,
          onConfirm: this.onConfirm,
          onCancel: this.onCancel,
        }

        if (ui.mobile) {
          this.bottomSheet.show('payments-split', {
            to: this.to,
            props: splitModalProps,
          })
        } else {
          this.modal.show('payments-split-modal', {
            props: splitModalProps,
          })
        }
      }
    },
    onConfirm({
      pointsToUse,
      pointsAmount,
      cardAmount,
      bankAccountAmount,
      ewalletAmount,
    }) {
      if (pointsToUse > 0) {
        this.selectedPoints = {
          ...this.selectedPoints,
          usePoints: pointsToUse,
          calculatedAmount: pointsAmount,
        }
      } else {
        this.selectedPoints = null
      }

      if (ewalletAmount > 0) {
        this.selectedEwallet = {
          ...this.selectedEwallet,
          ...(this.item.id !== this.selectedEwallet?.id && this.item),
          calculatedAmount: ewalletAmount,
        }
      } else {
        this.selectedEwallet = null
      }

      if (cardAmount > 0) {
        this.selectedCard = {
          ...this.selectedCard,
          calculatedAmount: cardAmount,
        }
      } else {
        this.selectedCard = null
      }

      if (bankAccountAmount > 0) {
        this.selectedBankAccount = {
          ...this.selectedBankAccount,
          calculatedAmount: bankAccountAmount,
        }
      } else {
        this.selectedBankAccount = null
      }

      this.getTransactionFees()
    },
    onCancel() {
      this.selectedPoints = null
      this.selectedEwallet = null
      this.selectedCard = null
      this.selectedBankAccount = null
      this.getTransactionFees()
    },
  },
}
</script>

<template>
  <div
    class="relative flex items-center rounded-lg border bg-white p-5"
    :class="[isUnselectable ? 'text-neutral-400 opacity-75' : 'cursor-pointer']"
    :style="{
      'box-shadow': isSelected ? '0px 0px 0px 3px #10B981' : null,
    }"
    @click="onClick"
  >
    <div
      class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full"
      :class="isSelected && 'bg-emerald-700 text-white'"
    >
      <base-icon
        :svg="isSelected ? 'v2/heroic/check' : 'plain/ewallet'"
        :size="isSelected ? 24 : 'xl'"
        :style="isUnselectable && 'filter:grayscale(1)'"
      />
    </div>
    <div class="ml-[15px] flex flex-col">
      <span class="font-bold">Pay with {{ ewalletLabels.ewalletCash }}</span>

      <span class="text-sm text-eonx-neutral-600">
        {{ details }}
      </span>
    </div>
    <div v-if="isSelected" class="ml-auto shrink-0">
      - {{ formatDollar(payWithEwallet) }}
    </div>
  </div>
</template>
