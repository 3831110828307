<script>
import bottomSheet from '/~/core/bottom-sheet'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import { formatDollar } from '/~/utils/format/money'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { useCheckout } from '/~/composables/checkout'
import { useLocalization } from '/~/composables/localization'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-bank-account-item',
  components: {
    BaseIcon,
    BaseLoader,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    to: {
      type: String,
      default: 'menu-modal',
    },
    splitEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      payWithBankAccount,
      payWithCard,
      payWithEwallet,
      payWithPoints,
      selectedPoints,
      selectedCard,
      selectedEwallet,
      selectedBankAccount,
      selectedAmount,
      getTransactionFees,
      transactionFees,
      loadingFees,
      payment,
      isBankAccountsDisabled,
    } = useCheckout()
    const { initiatingAccountsIds } = usePaymentMethods()
    const { isBankAccountsNavEnabled } = useProvider()
    const { burnPointsRateOrder } = usePoints()
    const { translate } = useLocalization()

    return {
      bottomSheet,
      formatDollar,
      payWithBankAccount,
      payWithCard,
      payWithEwallet,
      payWithPoints,
      selectedPoints,
      selectedCard,
      selectedEwallet,
      selectedBankAccount,
      selectedAmount,
      getTransactionFees,
      transactionFees,
      loadingFees,
      isBankAccountsNavEnabled,
      burnPointsRateOrder,
      initiatingAccountsIds,
      payment,
      isBankAccountsDisabled,
      modal,
      ui,
      translate,
    }
  },
  data: () => ({
    verifying: false,
  }),
  computed: {
    isSelected() {
      return (
        this.selectedBankAccount?.id === this.item.id && this.payWithBankAccount
      )
    },
    initializing() {
      return this.initiatingAccountsIds.includes(this.item.id)
    },
    isUnselectable() {
      if (this.payment.submitting) {
        return true
      }

      return this.remainingTotal === 0
    },
    remainingTotal() {
      return Math.max(
        this.payment.subTotalWithProgramFeesNoCoupons -
          this.payWithEwallet -
          this.payWithPoints,
        0
      )
    },
    status() {
      return (this.item.status ?? '').toLowerCase()
    },
    isVerified() {
      return this.status === 'verified' || !this.isBankAccountsNavEnabled
    },
    isBlocked() {
      return this.status === 'blocked' || this.status === 'locked'
    },
    methodFees() {
      if (this.loadingFees || Boolean(this.selectedCard)) {
        return 0
      } else {
        return this.transactionFees
      }
      // return this.method.fee ?? this.selectedMethod.fee ?? 0
    },
  },
  methods: {
    async onClick() {
      if (this.isBankAccountsDisabled) {
        if (this.isSelected) {
          this.selectedBankAccount = null
        }
        return
      }

      if (this.isBlocked || this.isUnselectable || this.initializing) return

      if (!this.isVerified) {
        await this.$router.push({
          hash: '#profile-payment-methods',
          query: this.$route.query,
        })
        bottomSheet.show('method-verify', {
          to: 'menu-modal',
          props: {
            method: this.item,
          },
        })
      } else {
        if (!this.splitEnabled || !this.selectedCard) {
          this.selectedBankAccount = this.isSelected
            ? null
            : {
                ...this.item,
                calculatedAmount: this.remainingTotal,
              }

          this.getTransactionFees()
        } else {
          const splitModalProps = {
            currentItem: this.item,
            selectedPoints: this.selectedPoints,
            burnPointsRate: this.burnPointsRateOrder,
            selectedEwallet: this.selectedEwallet,
            selectedCard: this.selectedCard,
            selectedBankAccount: this.selectedBankAccount,
            selectedAmount: this.selectedAmount,
            total: this.remainingTotal,
            onConfirm: this.onConfirm,
            onCancel: this.onCancel,
          }

          if (ui.mobile) {
            this.bottomSheet.show('payments-split', {
              to: this.to,
              props: splitModalProps,
            })
          } else {
            this.modal.show('payments-split-modal', {
              props: splitModalProps,
            })
          }
        }
      }
    },
    onConfirm({
      pointsToUse,
      pointsAmount,
      cardAmount,
      bankAccountAmount,
      ewalletAmount,
    }) {
      if (pointsToUse > 0) {
        this.selectedPoints = {
          ...this.selectedPoints,
          usePoints: pointsToUse,
          calculatedAmount: pointsAmount,
        }
      } else {
        this.selectedPoints = null
      }

      if (ewalletAmount > 0) {
        this.selectedEwallet = {
          ...this.selectedEwallet,
          calculatedAmount: ewalletAmount,
        }
      } else {
        this.selectedEwallet = null
      }

      if (cardAmount > 0) {
        this.selectedCard = {
          ...this.selectedCard,
          calculatedAmount: cardAmount,
        }
      } else {
        this.selectedCard = null
      }

      if (bankAccountAmount > 0) {
        this.selectedBankAccount = {
          ...this.selectedBankAccount,
          ...(this.item.id !== this.selectedBankAccount?.id && this.item),
          calculatedAmount: bankAccountAmount,
        }
      } else {
        this.selectedBankAccount = null
      }

      this.getTransactionFees()
    },
    onCancel() {
      this.selectedPoints = null
      this.selectedEwallet = null
      this.selectedCard = null
      this.selectedBankAccount = null
      this.getTransactionFees()
    },
  },
}
</script>

<template>
  <div
    class="relative flex flex-nowrap items-center rounded-lg border bg-white p-5"
    :class="[
      isUnselectable ? 'text-neutral-400 opacity-75' : 'cursor-pointer',
      !isVerified && 'text-neutral-400',
      isBlocked && 'pointer-events-none cursor-default',
      { 'cursor-default grayscale': isBankAccountsDisabled && !isSelected },
    ]"
    :style="{
      'box-shadow': isSelected ? '0px 0px 0px 3px #10B981' : null,
    }"
    @click="onClick"
  >
    <div
      class="flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
      :class="isSelected ? 'bg-emerald-700' : 'bg-primary'"
    >
      <base-icon
        :svg="isSelected ? 'v2/heroic/check' : 'v2/custom/bank'"
        :size="isSelected ? 24 : 'md'"
        :style="!isVerified && 'filter:grayscale(1)'"
      />
    </div>
    <div class="ml-[15px] flex flex-col overflow-hidden">
      <span
        class="truncate font-bold"
        :class="!isVerified && 'text-eonx-neutral-600'"
      >
        {{ item.accountName }}
      </span>
      <span
        class="text-sm"
        :class="isVerified ? 'text-eonx-neutral-600' : 'text-eonx-neutral-600'"
      >
        {{
          translate('bankAccount.details', {
            acc: `*** *** *${item.accountNumber.slice(-2)}`,
            bsb: item.bsb,
          })
        }}
      </span>
    </div>
    <div v-if="isSelected" class="ml-auto shrink-0 text-right sm:text-left">
      <div>- {{ formatDollar(payWithBankAccount + methodFees) }}</div>
      <div v-if="methodFees" class="text-xs text-eonx-neutral-600">
        Payment Processing fee: -{{ formatDollar(methodFees) }}
      </div>
    </div>
    <base-loader v-if="initializing" class="ml-auto shrink-0" size="xs" />
    <div
      v-else-if="isBlocked"
      class="ml-auto shrink-0 text-sm text-eonx-neutral-600"
    >
      ACCOUNT BLOCKED
    </div>

    <div
      v-else-if="!isVerified"
      class="absolute right-0 top-0 -mr-2.5 -mt-[5px] whitespace-nowrap rounded-l-md bg-orange-400 px-6 text-xs font-bold uppercase leading-5 text-white md:mt-[15px]"
    >
      <span
        class="absolute right-0 inline-block h-0 w-0 border-solid border-orange-400 text-amber-600"
        :style="{
          top: '100%',
          'border-width': '9px 8px 0 0',
          'border-color': 'currentColor transparent transparent transparent',
        }"
      />
      Verify Account
    </div>
  </div>
</template>
