<script>
import { useCms } from '/~/composables/cms/use-cms'
import { usePaymentMethods } from '/~/composables/payment-methods'
import PaymentsEwalletItem from './payments-ewallet-item.vue'
import PaymentMethodBlock from '../payments-methods-block.vue'

export default {
  name: 'payments-ewallet',
  components: {
    PaymentMethodBlock,
    PaymentsEwalletItem,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    splitEnabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { ewalletLabels } = useCms()
    const {
      isPurchaseOrderEwalletsAvailable,
      eWallets,
      fetchingList: fetching,
    } = usePaymentMethods()

    return {
      ewalletLabels,
      fetching,
      isAvailable: isPurchaseOrderEwalletsAvailable,
      eWallets,
    }
  },
}
</script>

<template>
  <div v-if="isAvailable">
    <payment-method-block
      v-if="fetching || eWallets.length"
      :title="ewalletLabels.ewalletCash"
      :loading="fetching"
    >
      <payments-ewallet-item
        :item="eWallets[0]"
        :to="to"
        :split-enabled="splitEnabled"
      />
    </payment-method-block>
  </div>
</template>
